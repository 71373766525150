import React, { useEffect } from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/Seo"
import colors from "../utils/colorData"
import { applyTheme } from "../utils/themeSwitcher"
import Link from "../components/Link"

const ArticleLink = styled(Link)`
  text-decoration-color: ${props => props.color};
  &:hover {
    color: ${props => props.color};
    text-decoration-color: ${props => props.color};
  }
`

const IndexPage = ({ data }) => {
  const colorIndex = Math.floor(Math.random() * colors.length) + 1

  useEffect(() => {
    applyTheme(colors[colorIndex])
  }, [colorIndex])

  const articles = data.allSitePage.edges

  return (
    <Layout>
      <SEO />
      {articles.map(data => {
        const article = data.node.context
        const { r, g, b } = article.color
        const color = `rgb(${r},${g},${b})`

        return (
          <h1
            className="mt0 mb4 mb5-l pt0-ns pt1 f3 f2-l measure serif"
            key={article.slug}
          >
            <ArticleLink color={color} to={`/${article.slug}`}>
              {article.title}
            </ArticleLink>
          </h1>
        )
      })}
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query ArticleQuery {
    allSitePage(
      sort: { order: DESC, fields: [context___date] }
      filter: { context: { isArticle: { eq: true } } }
    ) {
      edges {
        node {
          context {
            title
            slug
            color {
              r
              g
              b
            }
          }
        }
      }
    }
  }
`
